import { googleSignIn } from '@/api/api'
import { USER_ROLE } from "@/constants/constants";

const auth = {
  namespaced: true,

  state() {
    return {
      googleUser: {},
      user: {},
      idToken: ''
    };
  },

  getters: {
    idToken: (state) => state.idToken,
    userToken: (state) => state?.user?.token,
    user: (state) => state.user,
    userRole: (state) => state.user?.role,
    googleUser: (state) => state.googleUser,
    isLoggedIn: (state) => !!state?.user?.token,
    isManager: (state) => state.user?.role === USER_ROLE.MANAGER
  },

  mutations: {
    updateUser(state, user) {
      state.user = user
    },

    updateIsLoggedIn(state, bool) {
      state.isLoggedIn = bool
    },

    updateIdToken(state, idToken) {
      state.idToken = idToken
    },

    updateGoogleUser(state, user) {
      state.googleUser = user
    }
  },

  actions: {
    logOut({ state }) {
      window.google.accounts.id.revoke(state.googleUser.sub, () => {
        location.reload()
      });
    },

    async fetchUser({ dispatch, getters }) {
      const idToken = getters.idToken
      if (!idToken) return

      const { data } = await googleSignIn(idToken)
      dispatch('updateUser', data)
    },

    updateUser({ commit }, user) {
      commit('updateUser', user)
    },

    updateGoogleUser({ commit }, user) {
      commit('updateGoogleUser', user)
    },

    updateIdToken({ commit }, token) {
      commit('updateIdToken', token)
    }
  },
};

export default auth;
