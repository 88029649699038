<template>
  <div class="form-signin text-center m-auto">
    <img class="mb-4" src="@/assets/img/logo.svg" alt="Platinum" width="269">
    <div id="buttonDiv" class="d-inline-block"></div>
  </div>
  <div class="position-fixed bottom-0 w-100 text-center m-auto mb-5">
    <img src="@/assets/img/logo_elemen.svg" alt="Element" width="72">
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
  },
  watch: {
    isLoggedIn: function (isLoggeIn) {
      if (isLoggeIn) {
        this.$router.push({ name: 'AnnotatorStats' })
      }
    }
  },
  mounted() {
    window.google.accounts.id.renderButton(
      document.getElementById("buttonDiv"), {}
    );
  }
}
</script>

<style lang="scss" scoped>
.form-signin {
  max-width: 330px;
  padding: 1rem;
  padding-bottom: 200px
}
</style>
