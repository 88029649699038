import axios from 'axios'
import {
    getUsers,
    changeUser
} from "@/api/api";


export default {
    namespaced: true,

    state() {
        return {
            users: [],
            userSource: null
        }
    },

    getters: {
        users: state => state.users
    },

    mutations: {
        updateUsers (state, users) {
            state.users = users
        },
        updateUserSource (state, source) {
            state.userSource = source
        }
    },

    actions: {
        async fetchUsers({ state, commit }) {
            commit('updateUsers', [])
            if (state.userSource) {
                state.userSource.cancel()
            }
            try {
                commit('updateUserSource', axios.CancelToken.source())
                const cancelToken = state.userSource.token
                const { data } = await getUsers(cancelToken)
                commit('updateUserSource', null)
                if (!data || data.length < 1) return
    
                commit('updateUsers', data)
            } catch (e) {
                if (!axios.isCancel(e)) throw e
            }
        },

        async changeUser(_, { role, uuid }) {
            await changeUser({ role, uuid })
        }
    }
}