import store from '@/store'
import * as jose from 'jose'

window.google.accounts.id.initialize({
  client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  callback: handleCredentialResponse,
  auto_select: true
});
window.google.accounts.id.prompt();

async function handleCredentialResponse(credentialResponse) {
  const token = credentialResponse.credential
  const claims = jose.decodeJwt(token)

  store.dispatch('auth/updateIdToken', token)
  store.dispatch('auth/updateGoogleUser', claims)
  await store.dispatch('auth/fetchUser')
  store.dispatch('stats/getStatsToday')
}
