import axios from 'axios'
import store from '@/store'

// Add auth token
axios.interceptors.request.use(function (config) {
    const token = store.getters['auth/userToken']
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config;
});


export function googleSignIn(token) {
    const api = 'verify_user'
    return axios.post(api, { token })
}

export function getAnnotationSession(cancelToken) {
    const api = 'annotationSession'
    return axios.get(api, { cancelToken })
}

export function loadImageList() {
    const api = 'loadImageList'
    return axios.get(api)
}

export function postAnnotationResult(payload) {
    const api = 'annotationResult'
    return axios.post(api, payload)
}

export function getReviewList(cancelToken, params) {
    const api = 'reviewList'
    return axios.get(api, { cancelToken, params })
}

export function getAnnotation(id) {
    const api = `/annotation/${id}`
    return axios.get(api)
}

export function updateAnnotation(id, payload) {
    const api = `/annotation/${id}`
    return axios.post(api, payload)
}

export function postReviewResult(payload) {
    const api = 'reviewResult'
    return axios.post(api, payload)
}

export function getUsers() {
    const api = 'users'
    return axios.get(api)
}

export function changeUser(payload) {
    const api = 'user'
    return axios.post(api, payload)
}

export function getFasAnnotation(isReal) {
    if (isReal === undefined) {
        const api = '/annotation/fas'
        return axios.get(api)
    } else {
        const api = '/annotation/fas/' + (isReal ? 'real' : 'fake')
        return axios.get(api)
    }
}

export function postFasAnnotation(payload) {
    const api = '/annotation/fas'
    return axios.post(api, payload)
}

export function getDepthAnnotation(practice = false) {
    const api = practice ? '/annotation/depth/practice' : '/annotation/depth'
    return axios.get(api)
}

export function postDepthAnnotation(payload, practice = false) {
    const api = practice ? '/annotation/depth/practice' : '/annotation/depth'
    return axios.post(api, payload)
}

export function getQuality(practice = false) {
    const api = practice ? '/annotation/quality/practice' : '/annotation/quality'
    return axios.get(api)
}

export function postQuality(payload, practice = false) {
    const api = practice ? '/annotation/quality/practice' : '/annotation/quality'
    return axios.post(api, payload)
}

export function getAnnotatorStats(from, to) {
    const api = `/annotator/stats?from=${from}&to=${to}`
    return axios.get(api)
}

export function getAnnotatorStatsAid(aid, from, to) {
    const api = `/annotator/stats?aid=${aid}&from=${from}&to=${to}`
    return axios.get(api)
}

export function getAnnotatorSummary() {
    const api = `/annotator/summary`
    return axios.get(api)
}

export function getAnnotatorSummaryAid(aid) {
    const api = `/annotator/summary?aid=${aid}`
    return axios.get(api)
}


export function getMatchAnnotation() {
    const api = `/annotation/match`
    return axios.get(api)
}

export function postMatchAnnotation(payload) {
    const api = `/annotation/match`
    return axios.post(api, payload)
}

export function getSpoofAnnotation() {
    const api = `/annotation/spoofQuality`
    return axios.get(api)
}

export function postSpoofAnnotation(payload) {
    const api = `/annotation/spoofQuality`
    return axios.post(api, payload)
}

export function getReviewQualityAnnotation(imageCount) {
    const api = `/annotation/reviewQuality?count=${imageCount}`
    return axios.get(api)
}

export function postReviewQualityAnnotation(payload) {
    const api = `/annotation/reviewQuality`
    return axios.post(api, payload)
}

export function getPrevAnnotation(timestamp) {
    const api = `/annotation/previous?timestamp=${timestamp}`
    return axios.get(api)
}

export function getReviewBatch7Annotation(imageCount) {
    const api = `/annotation/reviewBatch7?count=${imageCount}`
    return axios.get(api)
}

export function getMovieFrameAnnotation(imageCount) {
    const api = `/movie-frame?count=${imageCount}`
    return axios.get(api)
}

export function getTrailerFrameAnnotation(imageCount) {
    const api = `/trailer-frame?count=${imageCount}`
    return axios.get(api)
}

export function getBboxToolQuery(query) {
    const api = `/bbox-tool-query?query=${query}`
    return axios.get(api)
}

export function postReviewBatch7Annotation(payload) {
    const api = `/annotation/reviewBatch7`
    return axios.post(api, payload)
}

export function getReviewBatch6Annotation(imageCount) {
    const api = `/annotation/reviewBatch6?count=${imageCount}`
    return axios.get(api)
}

export function postReviewBatch6Annotation(payload) {
    const api = `/annotation/reviewBatch6`
    return axios.post(api, payload)
}

export function getBBoxBatch7Annotation(imageCount) {
    const api = `/bbox-batch7?count=${imageCount}`
    return axios.get(api)
}

export function postBBoxBatch7Annotation(payload) {
    const api = `/bbox-batch7`
    return axios.post(api, payload)
}

export function getArtistReviewAnnotation() {
    const api = `/artist-review`
    return axios.get(api)
}

export function postArtistReviewAnnotation(payload) {
    const api = `/artist-review`
    return axios.post(api, payload)
}

export function postMovieFrameAnnotation(payload) {
    const api = `/movie-frame`
    return axios.post(api, payload)
}

export function postTrailerFrameAnnotation(payload) {
    const api = `/trailer-frame`
    return axios.post(api, payload)
}

export function getManagerReviewAnnotation(imageCount) {
    const api = `/annotation/managerReview?count=${imageCount}`
    return axios.get(api)
}

export function getPracticeReviewAnnotation(imageCount) {
    const api = `/annotation/practice/review?count=${imageCount}`
    return axios.get(api)
}


export function postManagerReviewAnnotation(payload) {
    const api = `/annotation/managerReview`
    return axios.post(api, payload)
}

export function postPracticeReviewAnnotation(payload) {
    const api = `/annotation/practice/review`
    return axios.post(api, payload)
}

export function getMovieBBoxReview(imageCount) {
    const api = `/movie-bbox-review?count=${imageCount}`
    return axios.get(api)
}

export function postMovieBBoxReview(payload) {
    const api = '/movie-bbox-review'
    return axios.post(api, payload)
}

export function getPosterBBoxReview(imageCount) {
    const api = `/poster-bbox-review?count=${imageCount}`
    return axios.get(api)
}

export function postPosterBBoxReview(payload) {
    const api = '/poster-bbox-review'
    return axios.post(api, payload)
}

export function getTrailerBBoxReview(imageCount) {
    const api = `/trailer-bbox-review?count=${imageCount}`
    return axios.get(api)
}

export function postTrailerBBoxReview(payload) {
    const api = '/trailer-bbox-review'
    return axios.post(api, payload)
}
