// Bootstrap
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/tooltip";
import "./assets/global.scss";

import axios from 'axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/googleAuth';
import Select2 from 'vue3-select2-component'

axios.defaults.baseURL = (process.env.VUE_APP_API_URL || '') + '/api/v1';

createApp(App)
  .use(store)
  .use(router)
  .component('Select2', Select2)
  .mount('#app');
