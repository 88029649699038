<template>
  <div class="bg-landing d-flex align-items-center py-4 w-100 m-auto">
    <router-view></router-view>
  </div>
</template>

<style scoped>
.bg-landing {
  min-height: 100vh;
  background-image: url('@/assets/img/index_bg.png');
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
}
</style>

<!-- <template>
  <div>
    <Navbar />
    <Alerts />

    <div class="container-fluid p-5">
      <router-view :key="$route.fullPath"/>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Alerts from '@/components/Alerts'

export default {
  name: 'App',
  components: { Navbar, Alerts }
}
</script> -->
