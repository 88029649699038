export const USER_ROLE = {
  ANNOTATOR: 'annotator',
  SUPERVISOR: 'supervisor',
  MANAGER: 'manager'
}

export const REVIEW_STATUS = {
  NOT_REVIEWED: 'not_reviewed',
  APPROVED: 'approved',
  REJECTED: 'rejected'
}

export const ANNOTATION_RESULT = {
  SELECTED: 'selected',
  NOT_SELECTED: 'not_selected'
}

export const FAS_RESULT = {
  REAL: 'real',
  FAKE: 'fake'
}

export const NORMAL_VERDICT = {
  NOT_NORMAL: {
    value: "NOT_NORMAL",
    text: "NOT Normal",
    key: "X",
    keycode: "KeyX"
  },
  NORMAL: {
    value: "NORMAL",
    text: "Normal",
    key: "1",
    keycode: "Digit1"
  },
}

export const BBOX_OK_VERDICT = {
  INCORRECT: {
    value: "INCORRECT",
    text: "Incorrect",
    key: "Z",
    keycode: "KeyZ"
  },
  BAD_QUALITY: {
    value: "BAD_QUALITY",
    text: "Bad Quality",
    key: "X",
    keycode: "KeyX"
  },
  OK: {
    value: "OK",
    text: "Ok",
    key: "1",
    keycode: "Digit1"
  },
}

export const ARTIST_OK_VERDICT = {
  EXCLUDED: {
    value: "EXCLUDED",
    text: "Excluded",
    key: "X",
    keycode: "KeyX"
  },
  OK: {
    value: "OK",
    text: "Ok",
    key: "1",
    keycode: "Digit1"
  },
}

export const FRAME_VERDICT = {
  EXCLUDED: {
    value: "EXCLUDED",
    text: "Excluded",
    key: "X",
    keycode: "KeyX"
  },
  POOL_QUALITY: {
    value: "POOL_QUALITY",
    text: "Poor Quality",
    key: "Q",
    keycode: "KeyQ"
  },
  CREDITS: {
    value: "CREDITS",
    text: "Opening or Ending Credits",
    key: "C",
    keycode: "KeyC"
  },
  CHAR_OFF: {
    value: "CHAR_OFF",
    text: "Character off frame",
    key: "F",
    keycode: "KeyF"
  },
  OK: {
    value: "OK",
    text: "OK",
    key: "1",
    keycode: "Digit1"
  },
}

export const NORMAL_ASSETS = {
  [NORMAL_VERDICT.NORMAL.value]: { cursor: "cursor-normal", color: "bg-normal", images: [] },
  [NORMAL_VERDICT.NOT_NORMAL.value]: { cursor: "cursor-corrupted-img", color: "bg-corrupted-img", images: [] },
};

export const BBOX_OK_ASSETS = {
  [BBOX_OK_VERDICT.OK.value]: { cursor: "cursor-normal", color: "bg-normal", images: [] },
  [BBOX_OK_VERDICT.BAD_QUALITY.value]: { cursor: "cursor-other", color: "bg-bad-quality", images: [] },
  [BBOX_OK_VERDICT.INCORRECT.value]: { cursor: "cursor-corrupted-img", color: "bg-corrupted-img", images: [] },
};

export const ARTIST_OK_ASSETS = {
  [ARTIST_OK_VERDICT.OK.value]: { cursor: "cursor-normal", color: "bg-normal", images: [] },
  [ARTIST_OK_VERDICT.EXCLUDED.value]: { cursor: "cursor-corrupted-img", color: "bg-excluded-img", images: [] },
};

export const QUALITY_VERDICT = {
  CUT_OFF: {
    value: "CUT_OFF",
    text: "Cut Off",
    key: "W",
    keycode: "KeyW"
  },
  BRIGHT: {
    value: "BRIGHT",
    text: "Bright",
    key: "X",
    keycode: "KeyX"
  },
  DARK: {
    value: "DARK",
    text: "Dark",
    key: "C",
    keycode: "KeyC"
  },
  BLURRY: {
    value: "BLURRY",
    text: "Blurry",
    key: "Q",
    keycode: "KeyQ"
  },
  EYES_UNSEEN: {
    value: "EYES_UNSEEN",
    text: "Eyes Unseen",
    key: "2",
    keycode: "Digit2"
  },
  MAKEUP: {
    value: "MAKEUP",
    text: "Makeup",
    key: "V",
    keycode: "KeyV"
  },
  OCCLUSION: {
    value: "OCCLUSION",
    text: "Occlusion",
    key: "A",
    keycode: "KeyA"
  },
  BEND: {
    value: "BEND",
    text: "Bend",
    key: "F",
    keycode: "KeyF"
  },
  EXTREME_ANGLE: {
    value: "EXTREME_ANGLE",
    text: "Extreme Angle",
    key: "R",
    keycode: "KeyR"
  },
  GLASSES: {
    value: "GLASSES",
    text: "Glasses",
    key: "G",
    keycode: "KeyG"
  },
  HIJAB: {
    value: "HIJAB",
    text: "Hijab",
    key: "H",
    keycode: "KeyH"
  },
  NORMAL: {
    value: "NORMAL",
    text: "Normal",
    key: "1",
    keycode: "Digit1"
  },
  FACE_MISSING: {
    value: "FACE_MISSING",
    text: "Face Missing",
    key: "E",
    keycode: "KeyE"
  },
  MULTIPLE_FACES: {
    value: "MULTIPLE_FACES",
    text: "Multiple Faces",
    key: "S",
    keycode: "KeyS"
  },
  CORRUPTED_IMAGE: {
    value: "CORRUPTED_IMAGE",
    text: "Corrupted Image",
    key: "9",
    keycode: "Digit9"
  },
  OTHER: {
    value: "OTHER",
    text: "Other",
    key: "D",
    keycode: "KeyD"
  },
}

export const QUALITY_ASSETS = {
  [QUALITY_VERDICT.BLURRY.value]: {
    cursor: "cursor-blurry",
    color: "bg-blurry text-dark",
    images: [
      '/img/quality/photo_blurry_01.png',
      '/img/quality/photo_blurry_02.png',
      '/img/quality/photo_blurry_03.png',
      '/img/quality/photo_blurry_04.png',
      '/img/quality/photo_blurry_05.png',
      '/img/quality/photo_blurry_06.png'
    ]
  },
  [QUALITY_VERDICT.CUT_OFF.value]: {
    cursor: "cursor-cut-off",
    color: "bg-cut-off text-dark",
    images: [
      '/img/quality/photo_cut-off_01.png',
      '/img/quality/photo_cut-off_02.png',
      '/img/quality/photo_cut-off_03.png',
      '/img/quality/photo_cut-off_04.png'
    ]
  },
  [QUALITY_VERDICT.FACE_MISSING.value]: {
    cursor: "cursor-face-missing",
    color: "bg-face-missing text-dark",
    images: [
      '/img/quality/photo_face-missing_01.png',
      '/img/quality/photo_face-missing_01-1.png',
      '/img/quality/photo_face-missing_02.png',
      '/img/quality/photo_face-missing_02-1.png',
      '/img/quality/photo_face-missing_03.png',
      '/img/quality/photo_face-missing_03-1.png',
      '/img/quality/photo_face-missing_04.png',
      '/img/quality/photo_face-missing_04-1.png',
      '/img/quality/photo_face-missing_05.png',
      '/img/quality/photo_face-missing_05-1.png',
      '/img/quality/photo_face-missing_06.png',
      '/img/quality/photo_face-missing_06-1.png'
    ]
  },
  [QUALITY_VERDICT.EXTREME_ANGLE.value]: {
    cursor: "cursor-extreme-angle",
    color: "bg-extreme-angle text-dark",
    images: [
      '/img/quality/photo_extreme-angle_01.png',
      '/img/quality/photo_extreme-angle_02.png',
      '/img/quality/photo_extreme-angle_03.png',
      '/img/quality/photo_extreme-angle_04.png'
    ]
  },
  [QUALITY_VERDICT.OCCLUSION.value]: {
    cursor: "cursor-occlusion",
    color: "bg-occlusion text-dark",
    images: [
      '/img/quality/photo_occlusion_01.png',
      '/img/quality/photo_occlusion_02.png',
      '/img/quality/photo_occlusion_03.png',
      '/img/quality/photo_occlusion_04.png',
      '/img/quality/photo_occlusion_05.png',
      '/img/quality/photo_occlusion_06.png'
    ]
  },
  [QUALITY_VERDICT.MULTIPLE_FACES.value]: {
    cursor: "cursor-multiple-faces",
    color: "bg-multiple-faces text-dark",
    images: ['/img/quality/photo_multiple-faces.png']
  },
  [QUALITY_VERDICT.BRIGHT.value]: {
    cursor: "cursor-bright",
    color: "bg-bright text-dark",
    images: [
      '/img/quality/photo_bright_01.png',
      '/img/quality/photo_bright_02.png',
      '/img/quality/photo_bright_03.png',
      '/img/quality/photo_bright_04.jpg',
      '/img/quality/photo_bright_05.png',
      '/img/quality/photo_bright_06.png',
      '/img/quality/photo_bright_07.png',
      '/img/quality/photo_bright_08.png'
    ]
  },
  [QUALITY_VERDICT.DARK.value]: {
    cursor: "cursor-dark",
    color: "bg-dark text-dark",
    images: [
      '/img/quality/photo_dark_01.png',
      '/img/quality/photo_dark_02.png',
      '/img/quality/photo_dark_03.png',
      '/img/quality/photo_dark_04.jpg',
      '/img/quality/photo_dark_05.png',
      '/img/quality/photo_dark_06.png'
    ]
  },
  [QUALITY_VERDICT.OTHER.value]: { cursor: "cursor-other", color: "bg-other text-dark", images: ['/img/quality/photo_other.png'] },
  [QUALITY_VERDICT.NORMAL.value]: {
    cursor: "cursor-normal",
    color: "bg-normal text-white",
    images: [
      '/img/quality/photo_normal_01.png',
      '/img/quality/photo_normal_02.png',
      '/img/quality/photo_normal_03.png',
      '/img/quality/photo_normal_04.png',
      '/img/quality/photo_normal_05.png',
      '/img/quality/photo_normal_06.png',
      '/img/quality/photo_normal_07.png',
      '/img/quality/photo_normal_08.png'
    ]
  },
  [QUALITY_VERDICT.CORRUPTED_IMAGE.value]: {
    cursor: "cursor-corrupted",
    color: "bg-corrupted text-white",
    images: ['/img/quality/photo_corrupted.png']
  },
  [QUALITY_VERDICT.BEND.value]: {
    cursor: "cursor-bend",
    color: "bg-bend text-dark",
    images: [
      '/img/quality/photo_bend_01.png',
      '/img/quality/photo_bend_02.png',
      '/img/quality/photo_bend_03.png',
      '/img/quality/photo_bend_04.png',
      '/img/quality/photo_bend_05.png',
      '/img/quality/photo_bend_06.png'
    ]
  },
  [QUALITY_VERDICT.EYES_UNSEEN.value]: {
    cursor: "cursor-eyes-unseen",
    color: "bg-eyes-unseen text-dark",
    images: [
      '/img/quality/photo_eyes-unseen_01.png',
      '/img/quality/photo_eyes-unseen_02.png',
      '/img/quality/photo_eyes-unseen_03.png',
      '/img/quality/photo_eyes-unseen_04.png',
      '/img/quality/photo_eyes-unseen_05.png',
      '/img/quality/photo_eyes-unseen_06.png',
      '/img/quality/photo_eyes-unseen_07.png'
    ]
  },
  [QUALITY_VERDICT.GLASSES.value]: {
    cursor: "cursor-glasses",
    color: "bg-glasses text-dark",
    images: [
      '/img/quality/photo_glasses_01.png',
      '/img/quality/photo_glasses_02.png',
      '/img/quality/photo_glasses_03.png',
      '/img/quality/photo_glasses_04.png',
      '/img/quality/photo_glasses_05.png'
    ]
  },
  [QUALITY_VERDICT.HIJAB.value]: {
    cursor: "cursor-hijab",
    color: "bg-hijab text-dark",
    images: [
      '/img/quality/photo_hijab_01.png',
      '/img/quality/photo_hijab_02.png',
      '/img/quality/photo_hijab_03.png',
      '/img/quality/photo_hijab_04.png',
      '/img/quality/photo_hijab_05.png',
      '/img/quality/photo_hijab_06.png'
    ]
  },
  [QUALITY_VERDICT.MAKEUP.value]: {
    cursor: "cursor-makeup",
    color: "bg-makeup text-dark",
    images: [
      '/img/quality/photo_makeup_01.png',
      '/img/quality/photo_makeup_02.png',
      '/img/quality/photo_makeup_03.png'
    ]
  },
};

export const QUALITY_COLORS = {
  [QUALITY_VERDICT.BLURRY.value]: "bg-blurry text-dark",
  [QUALITY_VERDICT.CUT_OFF.value]: "bg-cutoff text-dark",
  [QUALITY_VERDICT.FACE_MISSING.value]: "bg-face_missing text-dark",
  [QUALITY_VERDICT.EXTREME_ANGLE.value]: "bg-extreme_angle text-dark",
  [QUALITY_VERDICT.OCCLUSION.value]: "bg-occlusion text-dark",
  [QUALITY_VERDICT.MULTIPLE_FACES.value]: "bg-multiple_faces text-white",
  [QUALITY_VERDICT.BRIGHT.value]: "bg-bright text-dark",
  [QUALITY_VERDICT.DARK.value]: "bg-dark text-text-white",
  [QUALITY_VERDICT.OTHER.value]: "bg-other text-dark",
  [QUALITY_VERDICT.NORMAL.value]: "bg-normal text-white",
  [QUALITY_VERDICT.CORRUPTED_IMAGE.value]: "bg-corrupted_image text-white",
  [QUALITY_VERDICT.BEND.value]: "bg-bend text-dark",
  [QUALITY_VERDICT.MAKEUP.value]: "bg-makeup text-dark",
  [QUALITY_VERDICT.EYES_UNSEEN.value]: "bg-eyes_unseen text-dark",
  [QUALITY_VERDICT.GLASSES.value]: "bg-eyes_unseen text-dark",
  [QUALITY_VERDICT.HIJAB.value]: "bg-eyes_unseen text-dark",
}

export const DEPTH_VERDICT = {
  FAR: {
    value: "FAR",
    text: "Far",
    key: "1",
    keycode: "Digit1"
  },
  MID: {
    value: "MID",
    text: "Mid",
    key: "2",
    keycode: "Digit2"
  },
  NEAR: {
    value: "NEAR",
    text: "Near",
    key: "3",
    keycode: "Digit3"
  },
  INVALID: {
    value: "INVALID",
    text: "Invalid",
    key: "4",
    keycode: "Digit4"
  },
  CORRUPTED_IMAGE: {
    value: "CORRUPTED_IMAGE",
    text: "Corrupted Image",
    key: "9",
    keycode: "Digit9"
  }
}

export const DEPTH_ASSETS = {
  [DEPTH_VERDICT.FAR.value]: { cursor: "cursor-far", color: "bg-far", images: [] },
  [DEPTH_VERDICT.MID.value]: { cursor: "cursor-mid", color: "bg-mid", images: [] },
  [DEPTH_VERDICT.NEAR.value]: { cursor: "cursor-near", color: "bg-near", images: [] },
  [DEPTH_VERDICT.INVALID.value]: { cursor: "cursor-invalid", color: "bg-invalid", images: [] },
  [DEPTH_VERDICT.CORRUPTED_IMAGE.value]: { cursor: "cursor-corrupted-img", color: "bg-corrupted-img", images: [] }
};

export const FRAME_ASSETS = {
  [FRAME_VERDICT.OK.value]: { cursor: "cursor-normal", color: "bg-normal", images: [] },
  [FRAME_VERDICT.CREDITS.value]: { cursor: "cursor-bright-img", color: "bg-bright", images: [] },
  [FRAME_VERDICT.CHAR_OFF.value]: { cursor: "cursor-char-off", color: "bg-char-off", images: [] },
  [FRAME_VERDICT.POOL_QUALITY.value]: { cursor: "cursor-occlusion-img", color: "bg-occlusion", images: [] },
  [FRAME_VERDICT.EXCLUDED.value]: { cursor: "cursor-corrupted-img", color: "bg-corrupted", images: [] }
};


export const MATCH_VERDICT = {
  SAME: {
    value: "SAME",
    text: "Same",
    key: "1",
    keycode: "Digit1"
  },
  DIFFERENT: {
    value: "DIFFERENT",
    text: "Different",
    key: "2",
    keycode: "Digit2"
  },
  NOT_SURE: {
    value: "NOT_SURE",
    text: "Not Sure",
    key: "3",
    keycode: "Digit3"
  },
  ID1_INCONSISTENT: {
    value: "ID1_INCONSISTENT",
    text: "ID1 Inconsistent",
    key: "4",
    keycode: "Digit4"
  },
  ID2_INCONSISTENT: {
    value: "ID2_INCONSISTENT",
    text: "ID2 Inconsistent",
    key: "5",
    keycode: "Digit5"
  },
  ALL_INCONSISTENT: {
    value: "ALL_INCONSISTENT",
    text: "Both ID Inconsistent",
    key: "6",
    keycode: "Digit6"
  },
  OTHER: {
    value: "OTHER",
    text: "Other",
    key: "9",
    keycode: "Digit9"
  },
}

export const MATCH_COLORS = {
  [MATCH_VERDICT.SAME.value]: "bg-success text-dark",
  [MATCH_VERDICT.DIFFERENT.value]: "bg-warning text-dark",
  [MATCH_VERDICT.NOT_SURE.value]: "bg-orange text-dark",
  [MATCH_VERDICT.ID1_INCONSISTENT.value]: "bg-secondary text-white",
  [MATCH_VERDICT.ID2_INCONSISTENT.value]: "bg-secondary text-white",
  [MATCH_VERDICT.ALL_INCONSISTENT.value]: "bg-secondary text-white",
  [MATCH_VERDICT.OTHER.value]: "bg-secondary text-white",
};

export const BBOX_EXTRA_LABELS = [
  { display: 'Excluded', label: 'excluded' },
  { display: 'Other Character', label: 'other_character' },
  { display: 'Skip', label: 'skip' }
]
