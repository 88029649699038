<template>
  <div
    class="position-fixed start-50 text-center translate-middle-x"
    style="top: 10px; z-index: 10;"
  >
    <div
      v-for="alert in alerts"
      :key="alert"
      class="alert alert-dismissible fade show"
      :class="colorMap[alert.type]"
      role="alert"
    >
      {{ alert.message }}
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AlertsComponent",
  data () {
      return {
          colorMap: {
              success: 'alert-success',
              warning: 'alert-warning',
              danger: 'alert-danger'
          }
      }
  },
  computed: {
    ...mapGetters("alert", ["alerts"]),
  },
};
</script>
