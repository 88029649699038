<template>
  <component :is="layout"/>
</template>

<script setup>
import { shallowRef } from 'vue'
import router from './router'
import layouts from './layouts'

const layout = shallowRef('div')

router.afterEach((to) => {
  layout.value = layouts[to.meta.layout] || 'LoginLayout'
})
</script>
