export default {
    namespaced: true,

    state: () => ({
        alerts: {}
    }),

    getters: {
        alerts: state => state.alerts
    },

    mutations: {
        addAlert(state, { id, alert }) {
            state.alerts[id] = alert
        },

        deleteAlert(state, id) {
            delete state.alerts[id]
        }
    },

    actions: {
        addAlert({ commit }, { message, type }) {
            const id = Date.now()
            const alert = { message, type }
            commit('addAlert', { id, alert })
            setTimeout(() => {
                commit('deleteAlert', id)
            }, 3000)
        },

        successAlert({ dispatch }, message) {
            dispatch('addAlert', { message, type: 'success'})
        },

        warningAlert({ dispatch }, message) {
            dispatch('addAlert', { message, type: 'warning'})
        },

        dangerAlert({ dispatch }, message) {
            dispatch('addAlert', { message, type: 'danger'})
        }
    }
}