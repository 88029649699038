<template>
  <div>
    <Navbar />
    <Alerts />

    <div class="container-fluid p-5">
      <router-view :key="$route.fullPath"/>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Alerts from '@/components/Alerts'

export default {
  name: 'App',
  components: { Navbar, Alerts }
}
</script>
