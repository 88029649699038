import axios from 'axios'
import {
    getAnnotationSession,
    loadImageList,
    postAnnotationResult,
} from "@/api/api";
import { ANNOTATION_RESULT } from '@/constants/constants'

export default {
    namespaced: true,

    state() {
        return {
            session: [],
            anchor: {},
            selected: new Set(),
            sessionSource: null
        }
    },

    getters: {
        session: state => state.session,
        anchor: state => state.anchor,
        selected: state => state.selected
    },

    mutations: {
        updateSession(state, session) {
            state.session = session
        },
        updateAnchor(state, anchor) {
            state.anchor = anchor
        },
        clearSelected(state) {
            state.selected.clear()
        },
        updateSelected(state, imageId) {
            if (state.selected.has(imageId)) {
                state.selected.delete(imageId);
            } else {
                state.selected.add(imageId);
            }
        },
        updateSessionSource(state, source) {
            state.sessionSource = source
        }
    },

    actions: {
        initSession({ commit }) {
            commit('updateAnchor', {})
            commit('updateSession', [])
            commit('clearSelected')
        },
        async getSession({ commit, state, dispatch }) {
            dispatch('initSession')
            if (state.sessionSource) {
                state.sessionSource.cancel()
            }
            try {
                commit('updateSessionSource', axios.CancelToken.source())
                const cancelToken = state.sessionSource.token
                const { data } = await getAnnotationSession(cancelToken)
                commit('updateSessionSource', null)
                if (!data) return

                commit('updateAnchor', data.anchorImage || {})
                commit('updateSession', data.images || [])
            } catch (e) {
                if (!axios.isCancel(e)) throw e
            }
        },
        async loadImage() {
            await loadImageList();
        },
        selectImage({ commit }, imageId) {
            commit('updateSelected', imageId)
        },
        async submitAnnotation({ rootGetters, state }) {
            const annotatedImages = Object.values(state.session).map(({ imageId }) => {
                return {
                    imageId,
                    result: state.selected.has(imageId)
                        ? ANNOTATION_RESULT.SELECTED
                        : ANNOTATION_RESULT.NOT_SELECTED,
                };
            });

            const payload = {
                userToken: rootGetters['auth/user'].token,
                createTimestamp: Date.now(),
                anchorImageId: state.anchor.imageId,
                anchorImageIdentification: state.anchor.identification,
                annotatedImages
            };

            await postAnnotationResult(payload);
        }
    }
}