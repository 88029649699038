import throttle from "lodash/throttle";
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import { getAnnotatorStats } from "@/api/api";

export default {
  namespaced: true,

  state() {
    return {
      statsToday: {}
    }
  },

  getters: {
    statsToday: state => state.statsToday,
    statsSummary: state => state.statsSummary,
  },

  mutations: {
    updateStatsToday(state, stats) {
      state.statsToday = stats
    }
  },

  actions: {
    getStatsToday: throttle(async function({ commit }) {
      const from = startOfDay(new Date()).getTime()
      const to = endOfDay(new Date()).getTime()
      const { data } = await getAnnotatorStats(from, to);

      commit("updateStatsToday", data)
    }, 3000)
  }
}
