import axios from 'axios'
import {getFasAnnotation, postFasAnnotation} from "@/api/api";
import {FAS_RESULT} from "@/constants/constants";

export default {
  namespaced: true,
  state() {
    return {
      images: [],
      result: {},
      source: null
    }
  },

  getters: {
    images: state => state.images,
    result: state => state.result
  },

  mutations: {
    updateImages(state, images) {
      state.images = images
    },
    updateResult(state, { id, result }) {
      state.result[id] = result
    },
    clearResult(state) {
      state.result = {}
    },
    updateSource(state, source) {
      state.source = source
    }
  },

  actions: {
    initFas({ commit, state }) {
      commit('updateImages', [])
      commit('clearResult')
      if (state.source) {
        state.source.cancel()
      }
    },
    async getAnnotation({ dispatch, commit }, isReal) {
      dispatch('initFas')

      try {
        commit('updateSource', axios.CancelToken.source())
        const { data } = await getFasAnnotation(isReal)
        commit('updateSource', null)
        if (!data) return

        commit('updateImages', data.images || [])
        for (const image of data.images) {
          const id = image.id
          const result = FAS_RESULT.REAL
          commit('updateResult', { id, result })
        }
      } catch (e) {
        if (!axios.isCancel(e)) throw e
      }
    },

    async postAnnotation({ state }) {
      const payload = {
        'images': Object.entries(state.result).map(([id, verdict]) => {
          return {id, verdict}
        })
      }
      await postFasAnnotation(payload)
    },

    updateResult({ commit }, { id, result }) {
      commit('updateResult', { id, result })
    }
  }
}