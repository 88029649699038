import { createStore } from 'vuex'
import auth from './auth'
import annotate from './annotate'
import result from './result'
import alert from './alert'
import review from './review'
import user from './user'
import fas from './fas'
import stats from './stats'

export default createStore({
  modules: {
    auth,
    annotate,
    result,
    alert,
    review,
    user,
    fas,
    stats
  }
})
