import axios from 'axios'
import {
    getReviewList
} from "@/api/api";

const INIT_FILTER = {
    status: '',
    pageNumber: 0,
    pageSize: 10,
    showOwn: false
}

export default {
    namespaced: true,

    state() {
        return {
            reviewList: [],
            totalPages: 4,
            listSource: null,
            filter: { ...INIT_FILTER }
        }
    },

    getters: {
        reviewList: state => state.reviewList,
        reviewFilter: state => state.filter,
        reviewTotalPages: state => state.totalPages,
        submitFilter: (state, _, __, rootGetters) => {
            const filter = { ...state.filter }
            if (!filter.status) delete filter.status
            if (filter.showOwn) filter.annotatorUuid = rootGetters['auth/userToken']
            delete filter.showOwn
            return filter
        }
    },

    mutations: {
        updateReviewList(state, list) {
            state.reviewList = list
        },
        updateListSource(state, source) {
            state.listSource = source
        },
        updateReviewFilter(state, { key, value }) {
            state.filter[key] = value
        },
        updateReviewTotalPages(state, totalPages) {
            state.totalPages = totalPages
        },
    },

    actions: {
        initList({ commit }) {
            commit('updateReviewList', [])
        },
        updateReviewFilter({ commit }, { key, value }) {
            commit('updateReviewFilter', { key, value })
        },
        async fetchReviewList({ state, commit, getters }) {
            if (state.listSource) {
                state.listSource.cancel()
            }
            try {
                commit('updateListSource', axios.CancelToken.source())
                const cancelToken = state.listSource.token
                const { data } = await getReviewList(cancelToken, { ...getters.submitFilter })
                commit('updateListSource', null)
                if (!data) return

                commit('updateReviewList', data.annotations || [])
                commit('updateReviewTotalPages', data.totalPages || 0)
            } catch (e) {
                if (!axios.isCancel(e)) throw e
            }
        }
    }
}